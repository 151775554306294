import ScoreboardGame from './ScoreboardGame.js';

export default function ScoreboardToday(props) {

  const gameRows = [];
  if (props.games) {
    for (let i = 0; i < props.games.length; i += 2) {
      let one = null
      let two = null;
        one = (
          <div className={`col-12 col-md-6 mb-3`}>
            <ScoreboardGame game={props.games[i]} score={props.scores[i]}/>
          </div>
        );

      if (props.games[i + 1]) {
          two = (
            <div className={`col-12 col-md-6 mb-3`}>
              <ScoreboardGame game={props.games[i + 1]} score={props.scores[i + 1]}/>
            </div>
          );
      }
      if (one !== null || two !== null) {
        gameRows.push(
          <div key={i} className="row">
            {one}
            {two}
          </div>
        );
      }
    }
  }
  if (gameRows.length > 0) {
    return (
      <section className="EventsOnCurrentDate">
        <div className="EventsOnCurrentDate--container">
          <h2>Today's Games</h2>
          {gameRows}
        </div>
      </section>

    );
  } else {
    return null
  }
}
