import $ from 'jquery';
import React from 'react';
import Scoreboard from './Scoreboard.js';
import './ScoreboardContainer.css';
import ScoreboardToday from "./ScoreboardToday";

class SchoolScoreboardContainer extends React.Component {
  state = {
    scoreboard: this.props.initial,
  };

  componentDidMount() {
    if (this.props.url) {
      this._timeout = setTimeout(() => {
        this._load();
      }, 15 * 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout);
  }

  _load = () => {
    if (this.props.url) {
      $.getJSON(this.props.url).done(data => {
        this.setState({scoreboard: data});
        this._timeout = setTimeout(() => {
          this._load();
        }, 15 * 1000);
      });
    }
  };


  render() {
    const todayScoreboardObj = {
      games:[],
      scores:[],
    }
    const todayDate = new Date()
    todayDate.setHours(0, 0, 0, 0);

    if(this.state.scoreboard.games.length  === 0 ) {
        return <p className="NoGamesAvailable fst-italic text-center m-0">There are no game records available.</p>
    }

    for (let i = 0; i < this.state.scoreboard.games.length ; i++ ) {
      if (new Date(this.state.scoreboard.games[i].datetime).getDate() === todayDate.getDate()) {
        todayScoreboardObj["games"].push(this.state.scoreboard.games[i])
        todayScoreboardObj["scores"].push(this.state.scoreboard.scores[i])
      }
    }

    return (
      <>
        {
          !this.props.singleColumn &&  <ScoreboardToday
          games={todayScoreboardObj.games}
          scores={todayScoreboardObj.scores} />
        }
        <Scoreboard
          singleColumn={this.props.singleColumn}
          games={this.state.scoreboard.games.slice(-this.props.limiter)}
          scores={this.state.scoreboard.scores.slice(-this.props.limiter)} />
      </>
    );
  }
}

export default SchoolScoreboardContainer;
