import $ from 'jquery';
import cx from 'classnames';
import React from 'react';

class TabBarFollowButton extends React.Component {
  state = {
    following: !!this.props.initial,
  };

  _toggle = () => {
    if (this.state.following) {
      this.setState({following: false});
      $.post(this.props.thing._links.unfollow.href);
    } else {
      this.setState({following: true});
      $.post(this.props.thing._links.follow.href);
    }
  };

  render() {
    const className = cx('btn', 'join', 'no-outline', {
      'btn-success': this.state.following,
      'btn-primary': !this.state.following,
    });
    return (
      <button className={className} onClick={this._toggle}>
        {this.state.following ? 'Following' : 'Follow'}
      </button>
    );
  }
}

export default TabBarFollowButton;
