import $ from 'jquery';
import React from 'react';

class ButtonGroupFollowButton extends React.Component {
  state = {
    following: !!this.props.initial,
  };

  _toggle = () => {
    if (this.state.following) {
      this.setState({following: false});
      $.post(this.props.thing._links.unfollow.href);
    } else {
      this.setState({following: true});
      $.post(this.props.thing._links.follow.href);
    }
  };

  render() {
    return (
      <button className="btn btn-default btn-block no-outline" onClick={this._toggle}>
        {this.state.following ? 'Following' : 'Follow'}
      </button>
    );
  }
}

export default ButtonGroupFollowButton;
