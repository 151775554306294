import ngDateFilter from '../../ngDateFilter.js';

import React, {useState} from "react";

export default function ScoreboardGame({game, score}) {
  let gameTime = ngDateFilter(game.datetime, 'EEE, MMM d')
  let extended = null;
  const showScore = !game.scrimmage || game.show_scrimmage_scores;
  if (score && score.final) {
    extended = (
      <p className="m-0">
        Final
      </p>
    );
  } else if (score && score.extended_info && Object.keys(score.extended_info).length > 0) {
    extended = (
      <>
        &nbsp;
      <ScoreboardGameExtendedInfo score={score}/>
    </>
  );
  }

  return (
    <a className="bg-white TeamResult" href={game._links.self.href}>
      <table className="table u-collapseHead m-0">
        <thead>
          <tr>
            <th>time</th>
            <th>Logo</th>
            <th>Team Name</th>
            <th></th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr className="ScoreboardDetail">
            <td className="info" colSpan="5">
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex">{gameTime} &#x2022; {game.sport_name} {game.scrimmage ? 'Scrimmage' : null}</span>
                <span>{game.time}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="col-1">
              <img className="TeamLogoImg m-0" src={game._links.away_team_profile_picture.href} alt="team-logo"/>
            </td>
            <td className="col light">{game.away_team_name}</td>
            <td className="col-1"></td>
            <td className="col-1 text-center">{showScore ? (game.away_team_score) : null}</td>
          </tr>
          <tr>
            <td>
              <img className="TeamLogoImg m-0" src={game._links.home_team_profile_picture.href} alt="team-logo"/>
            </td>
            <td className="col light">{game.home_team_name}</td>
            <td className="col-1"></td>
            <td className="text-center">{showScore ? (game.home_team_score) : null}</td>
          </tr>
          <tr className="ScoreboardDetail p-0">
            <td className="info" colSpan="5" style={{height:"43px"}}>
              <div className="d-flex justify-content-between">
                <span className="ScoreboardLink-gtg text-start">Game Page <i className="fa-solid fa-chevron-right"></i></span>
                {extended}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </a>
  );
}

function ScoreboardGameExtendedInfo({score}) {
  const info = score.extended_info;
  if (!info || Object.keys(info).length === 0) {
    return (<span></span>);
  }
  if (score.extended_info_summary) {
    return (
      <span>{score.extended_info_summary}</span>
    );
  }
  return (<span></span>);
}
