import ReactDOM from 'react-dom';
import preloaded from '../../src/preloaded.js';
import ButtonGroupFollowButton from '../../src/follow/ButtonGroupFollowButton.js';
import SchoolScoreboardContainer from '../../src/scoreboard/2022/SchoolScoreboardContainer.js';
import TabBarFollowButton from '../../src/follow/TabBarFollowButton.js';

const b1 = document.getElementById('ButtonGroupFollowButton');
if (b1) {
  ReactDOM.render(
    <ButtonGroupFollowButton initial={preloaded.following} thing={preloaded.school} />
  , b1);
}

const b2 = document.getElementById('TabBarFollowButton');
if (b2) {
  ReactDOM.render(
    <TabBarFollowButton initial={preloaded.following} thing={preloaded.school} />
  , b2);
}

const target = document.getElementById('scoreboardContainer');

if (target) {
  let value = Number(target.dataset.gameCount) || preloaded.scoreboard.games.length
  let singleColumn = Boolean(target.dataset.singleColumn) || false;

  ReactDOM.render(
    <SchoolScoreboardContainer limiter={value} singleColumn={singleColumn} schoolName={preloaded.school?.short_name} initial={preloaded.scoreboard} url={preloaded.scoreboard_url} />
  , target);
}
