import ScoreboardGame from './ScoreboardGame.js';

export default function Scoreboard({singleColumn, ...props}) {

  const gameRows = [];
  if (props.games) {
    for (let i = 0; i < props.games.length; i += 2) {
      let one = (
        <div className={`col-12${!singleColumn ? ' col-md-6 mb-3' : ''}`}>
          <ScoreboardGame game={props.games[i]} score={props.scores[i]}/>
        </div>
      );
      let two = null;
      if (props.games[i + 1]) {
        two = (
          <div className={`col-12${!singleColumn ? ' col-md-6 mb-3' : ''}`}>
            <ScoreboardGame game={props.games[i + 1]} score={props.scores[i + 1]}/>
          </div>
        );
      }
      gameRows.push(
        <div key={i} className="row">
          {one}
          {two}
        </div>
      );
    }
  }
  return (
    <>
      {gameRows}
    </>
  );
}
